import { graphql, useStaticQuery } from "gatsby"
import { useSelector } from "react-redux"

export const useContentfulMetadata = () => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const query = useStaticQuery(graphql`
    query {
      allContentfulDefaultPageSeo {
        edges {
          node {
            seoTitle
            seoDescription
          }
        }
      }
    }
  `)

  return locale === "en-US"
    ? query.allContentfulDefaultPageSeo.edges[0].node
    : query.allContentfulDefaultPageSeo.edges[1].node
}
