import React from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import { useContentfulMetadata } from "../../hooks/useContentfulMetadata"
import Helmet from "react-helmet"

export const SEO = ({ seoData, children, isGenText }) => {
  const { seoTitle, seoDescription } = useContentfulMetadata()

  const {
    title: fallbackTitle,
    description: fallbackDescription,
  } = useSiteMetadata()

  const TITLES = [
    "heading-1",
    "heading-2",
    "heading-3",
    "heading-4",
    "heading-5",
    "heading-6",
  ]

  const handleGeneralText = data => {
    return data.content.map((item, index) => {
      let generalTextTitle = ""
      let generalTextContent = ""

      if (TITLES.includes(item.nodeType)) {
        generalTextTitle = item.content[0].value
      } else {
        const values = item.content.map(content => content.value)
        generalTextContent += values.join(" ") + " "
      }

      generalTextContent = generalTextContent.trim()

      return (
        <div
          key={generalTextTitle}
          style={{
            maxWidth: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className="title">{generalTextTitle}</h3>
          <p className="content">{generalTextContent}</p>
        </div>
      )
    })
  }

  const seo = {
    title: seoData?.title || seoTitle || fallbackTitle,
    description: seoData?.description || seoDescription || fallbackDescription,
    generalText: seoData?.generalText
      ? handleGeneralText(seoData?.generalText)
      : null,
  }

  return isGenText && seo.generalText ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {seo.generalText}
    </div>
  ) : (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {children}
    </Helmet>
  )
}
