import { withWidth } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ArrowForward from "@material-ui/icons/ArrowForward"
import React, { useState } from "react"
import { FormattedDate } from "react-intl"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"
import BlogArticleCard from "../components/BlogArticleCard"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Textfield from "../components/TextField"
import hello from "../images/hello.png"
import "../styles/commonStyleSheet.scss"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  media: {
    height: 400,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))

const BlogArticle = props => {
  const classes = useStyles()
  const { location, width } = props
  const isSmall = width === "xs" || width === "sm"
  let splittedArray = location.pathname.split("/")
  let id = splittedArray[splittedArray.length - 1]
  const locale = useSelector(state => state?.locale?.localeObj?.locale)

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState("")
  return (
    <Layout location={props.location} pageContext={props.pageContext}>
      <SEO />
      <div
        style={{ overflowX: "hidden" }}
        className={locale === "zh" && "chinese-version"}
      >
        <Grid
          container
          justify="center"
          style={
            isSmall
              ? {}
              : {
                  paddingTop: 100,
                }
          }
        >
          <Grid item xs={10} md={5}>
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "MabryProLight",
              }}
            >
              <FormattedDate
                value={new Date(data.createdat)}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </Typography>
            <Typography style={{ fontFamily: "Clearface", fontSize: 40 }}>
              {data.title}
            </Typography>
            <div
              style={{
                fontFamily: "MabryProLight",
                marginTop: -10,
                fontSize: 20,
                lineHeight: "26px",
              }}
              dangerouslySetInnerHTML={{ __html: data.detail }}
            ></div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{
            marginTop: 114,
          }}
        >
          <Grid item xs={10} md={11}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={data.img}
                title={data.title}
              ></CardMedia>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{ marginTop: 104, marginBottom: 104 }}
        >
          <Grid item xs={10} md={5}>
            <div
              style={{
                fontFamily: "MabryProLight",
                marginTop: -10,
                fontSize: 20,
                lineHeight: "26px",
              }}
              dangerouslySetInnerHTML={{ __html: data.text1 }}
            ></div>
            <Typography style={{ marginTop: 30, fontFamily: "MabryProLight" }}>
              <div
                style={{
                  fontFamily: "MabryProLight",
                  marginTop: -10,
                  fontSize: 20,
                  lineHeight: "26px",
                }}
                dangerouslySetInnerHTML={{ __html: data.text2 }}
              ></div>
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={10} md={6}>
            <Card className={classes.card} elevation={0}>
              <CardMedia
                className={classes.media}
                image={data.img2}
                title={data.title}
              ></CardMedia>
            </Card>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 104 }}>
          <Grid item xs={10} md={5}>
            <Typography style={{ fontFamily: "MabryProLight" }}>
              <div
                style={{
                  fontFamily: "MabryProLight",
                  marginTop: -10,
                  fontSize: 20,
                  lineHeight: "26px",
                }}
                dangerouslySetInnerHTML={{ __html: data.text3 }}
              ></div>
            </Typography>
            <Typography
              style={{
                marginTop: 30,
                marginBottom: 30,
                fontFamily: "MabryProLight",
              }}
            >
              <div
                style={{
                  fontFamily: "MabryProLight",
                  marginTop: -10,
                  fontSize: 20,
                  lineHeight: "26px",
                }}
                dangerouslySetInnerHTML={{ __html: data.text4 }}
              ></div>
            </Typography>
            <Typography
              style={{
                marginTop: 30,
                marginBottom: 30,
                fontFamily: "MabryProLight",
              }}
            >
              <div
                style={{
                  fontFamily: "MabryProLight",
                  marginTop: -10,
                  fontSize: 20,
                  lineHeight: "26px",
                }}
                dangerouslySetInnerHTML={{ __html: data.text5 }}
              ></div>
            </Typography>
            <Divider style={{ marginTop: 104 }} />
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 38 }}>
          <Grid item xs={10} md={5}>
            <Grid container justify="space-between">
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontFamily: "MabryProRegular",
                    fontSize: 16,
                  }}
                >
                  Share on
                </Typography>
              </Grid>
              <Grid item xs={7} md={3}>
                <Grid container>
                  <Grid item>
                    <div className={classes.arrow}>
                      <ArrowForward />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.arrow}>
                      <ArrowForward style={{ marginLeft: 20 }} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.arrow}>
                      <ArrowForward style={{ marginLeft: 20 }} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.arrow}>
                      <ArrowForward style={{ marginLeft: 20 }} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "95%", margin: "auto", marginTop: 104 }} />
        <Grid
          container
          justify="center"
          style={{ marginTop: 120, height: isSmall ? "auto" : 505 }}
        >
          <Grid item md={11}>
            <BlogArticleCard id={id} isSmall={isSmall} />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 104, marginBottom: 104 }} />
        <Textfield backgroundColor={"black"} image={hello} />
        <Grid container justify="center">
          <Grid xs={11} sm={10} item>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default withWidth()(BlogArticle)
