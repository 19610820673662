import { CircularProgress } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React, { useState } from "react"
import { IntlProvider } from "react-intl"
import moment from "moment"

const useStyles = makeStyles(theme => ({
  media: {
    height: 376,
  },
  cardpic: {
    height: 200,
  },
  image: {
    height: 500,
    width: "100%",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  mt60: {
    marginTop: 55,
  },
  card: {
    border: "1px solid rgba(1,2,4,0.05)",
    borderRadius: "2px",
    backgroundColor: "rgba(255,255,255,1)",
    boxShadow: "none",
  },
}))

const AboutCard = ({ isSmall, id, blogs }) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  return (
    <IntlProvider>
      <Grid container>
        <Grid item>
          <Typography
            variant="h5"
            component="h2"
            style={{ fontFamily: "Clearface", fontSize: 40 }}
          >
            Similar articles
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.mt60}>
        {loading ? (
          <CircularProgress />
        ) : (
          blogs.length > 0 &&
          blogs.map(
            ({
              node: {
                slug,
                blogMainImage,
                blogTitle,
                blogPublishDate,
                blogSubTitle,
              },
            }) => (
              <Grid
                item
                lg={3}
                xs={10}
                md={6}
                key={slug}
                component={Link}
                to={`/blog/${slug}`}
                style={{ textDecoration: "none" }}
              >
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardpic}
                    image={blogMainImage?.fluid?.src}
                    title={blogTitle}
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={[classes.subtitle, classes.uppercase]}
                      style={{ fontFamily: "MabryProLight" }}
                    >
                      {moment(blogPublishDate).format("DD MMM yyyy")}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.title}
                      style={{
                        fontFamily: "MabryProBold",
                        fontSize: 20,
                        marginTop: 4,
                      }}
                    >
                      {blogTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.subtitle}
                      dangerouslySetInnerHTML={{
                        __html: blogSubTitle?.childMarkdownRemark?.html,
                      }}
                      style={{
                        fontSize: "16px",
                        fontFamily: "MabryProLight",
                        margin: 0,
                        marginTop: -10,
                        width: "90%",
                      }}
                    ></Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          )
        )}
      </Grid>
    </IntlProvider>
  )
}
export default AboutCard
